// extracted by mini-css-extract-plugin
export var content = "InternalPageHero-module--content--eaf4c";
export var contentCategory = "InternalPageHero-module--contentCategory--c4d51";
export var contentContainer = "InternalPageHero-module--contentContainer--0e66f";
export var contentContainerWrap = "InternalPageHero-module--contentContainerWrap--4f593";
export var ctaContainer = "InternalPageHero-module--ctaContainer--76b61";
export var date = "InternalPageHero-module--date--dcaaf";
export var imageContainer = "InternalPageHero-module--imageContainer--985a8";
export var imageContainerWrap = "InternalPageHero-module--imageContainerWrap--009fd";
export var internalpagehero = "InternalPageHero-module--internalpagehero--c7b02";
export var kontentImageContainer = "InternalPageHero-module--kontentImageContainer--13bef";
export var logoImage = "InternalPageHero-module--logoImage--a3e89";
export var overlay = "InternalPageHero-module--overlay--acfd4";
export var tall = "InternalPageHero-module--tall--ac880";
export var text = "InternalPageHero-module--text--8b746";
export var xshort = "InternalPageHero-module--xshort--d20fb";