import { ImageItem } from "@kontent-ai/gatsby-components/dist/image-element";
import React from "react";
import { Cta } from "../../elements/Cta/Cta";
import * as styles from "./InternalPageHero.module.css";
//@ts-ignore
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { KontentImageElement } from "../../elements/KontentImageElement/KontentImageElement";
import { Image } from "../../../types";

interface Props {
  shortOrTall: "short" | "tall" | "xshort";
  desktopImage: {
    item: ImageItem & { gatsbyImageData?: IGatsbyImageData };
    alt: string;
  };
  mobileImage?: {
    item: ImageItem & { gatsbyImageData?: IGatsbyImageData };
    alt: string;
  };
  contentCategory?: string;
  headline?: string;
  dateTime?: string;
  introText?: string;
  link?: { label: string; href: string; target: string };
  id?: string;
  hideOverlay?: boolean;
  logoImage?: Image;
}

export const InternalPageHero: React.FC<Props> = ({
  shortOrTall,
  desktopImage,
  mobileImage,
  contentCategory,
  headline,
  dateTime,
  introText,
  logoImage,
  link,
  id,
  hideOverlay,
}) => {
  const breakpoints = useBreakpoint();
  let sectionClassName = styles.internalpagehero;

  switch (shortOrTall) {
    case "tall":
      sectionClassName += ` ${styles.tall}`;
      break;
    case "xshort":
      sectionClassName += ` ${styles.xshort}`;
      break;
  }

  let heroImage =
    mobileImage?.item?.url && !breakpoints.l ? mobileImage : desktopImage;

  const renderImage = () => {
    return (
      <KontentImageElement
        image={heroImage.item}
        className={styles.kontentImageContainer}
        alt={heroImage.alt}
        width={2500}
        options={{
          quality:
            heroImage.item?.size && heroImage.item?.size > 8000000 ? 15 : 75,
        }}
        layout="fullWidth"
      />
    );
  };

  return (
    <section className={sectionClassName} data-kontent-component-id={id}>
      <div className={styles.imageContainerWrap}>
        {!hideOverlay && <div className={styles.overlay}></div>}
        {heroImage && renderImage()}
      </div>
      <div className={styles.contentContainerWrap}>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            {logoImage && logoImage?.item?.url && (
              <div className={styles.logoImage}>
                <KontentImageElement
                  image={logoImage?.item}
                  alt={logoImage?.alt}
                  height={
                    logoImage?.item?.height < 130
                      ? logoImage?.item?.height
                      : 130
                  }
                  width={
                    logoImage?.item?.height < 130
                      ? logoImage?.item?.width
                      : (130 * logoImage?.item?.width) / logoImage?.item?.height
                  }
                />
              </div>
            )}
            {contentCategory && (
              <div
                className={styles.contentCategory}
                data-kontent-element-codename="content_category_tax"
              >
                {contentCategory}
              </div>
            )}
            <h1 data-kontent-element-codename="headline">{headline}</h1>
            {dateTime && (
              <div
                className={styles.date}
                data-kontent-element-codename="date_time"
              >
                {dateTime}
              </div>
            )}
            {introText && (
              <div
                className={styles.text}
                data-kontent-element-codename="intro_text"
              >
                {introText}
              </div>
            )}
            {link?.label && link?.href && (
              <div
                className={styles.ctaContainer}
                data-kontent-element-codename="link_configuration__link"
              >
                <Cta
                  href={link.href}
                  target={link.target}
                  label={link.label}
                  invert
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
