import React from "react";
import { graphql } from "gatsby";
import { InternalPageHero } from "./InternalPageHero";
import {
  KontentLinks,
  KontentMediaImage,
  KontentMultiChoice,
  StringValue,
} from "../../../types";
import { getMultiChoice } from "../../../lib/multichoice";
import { formatLinks } from "../../../lib/links";
import { formatImages } from "../../../lib/images";

interface Props {
  short_or_tall: KontentMultiChoice<"short" | "tall" | "xshort">;
  background_image: { value: KontentMediaImage[] };
  logo_image: { value: KontentMediaImage[] };
  content_category_tax: KontentMultiChoice<string>;
  headline: StringValue;
  date_time: StringValue;
  intro_text: StringValue;
  link_configuration__link: KontentLinks;
  id: string;
}

const InternalPageHeroContainer: React.FC<Props> = ({
  short_or_tall,
  background_image,
  content_category_tax,
  headline,
  date_time,
  intro_text,
  link_configuration__link,
  logo_image,
  id,
}) => {
  const shortOrTall = getMultiChoice(short_or_tall);
  const images = formatImages(background_image.value[0]?.elements);
  const cta = formatLinks(link_configuration__link?.modular_content);
  const logo = formatImages(logo_image?.value[0]?.elements);

  return (
    <InternalPageHero
      shortOrTall={shortOrTall}
      desktopImage={images.largeImage}
      mobileImage={images.smallImage}
      contentCategory={content_category_tax?.value[0]?.name}
      headline={headline.value}
      dateTime={date_time.value}
      introText={intro_text.value}
      link={cta && cta[0]}
      id={id}
      logoImage={logo?.largeImage}
    ></InternalPageHero>
  );
};

export default InternalPageHeroContainer;

export const fragmentInternalPageHero = graphql`
  fragment InternalPageHero on kontent_item_component___internal_page_hero {
    elements {
      short_or_tall {
        value {
          codename
        }
      }
      background_image {
        value {
          ...kontentMediaImage
          ... on kontent_item_media___image {
            elements {
              image {
                value {
                  gatsbyImageData
                }
              }
              small_image {
                value {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
      content_category_tax {
        value {
          name
        }
      }
      headline {
        value
      }
      date_time {
        value(formatString: "dddd, MMMM D, YYYY h:mm A")
      }
      intro_text {
        value
      }
      link_configuration__link {
        modular_content {
          ...kontentRichTextLink
        }
      }
      logo_image {
        value {
          ...kontentMediaImage
        }
      }
    }
  }
`;
